import { auth, db } from "./firebase33";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";



export const doCreateUserWithEmailAndPassword = async (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password)
  .then((result) => {
    setDoc(doc(db, "users", auth.currentUser.uid),
    
    {
      email, 
      "total": "$25.00",
      "_pdfplum_config": {
          "outputStorageBucket": "gs://a-custom-fit-in-motion.appspot.com",
          "outputFileName": `${auth.currentUser.uid}-PDF.pdf`,
          "returnPdfInResponse": true,
          "adjustHeightToFit": false,
          "chromiumPdfOptions": {
              "format": "A4",
              "printBackground": true
          }
      }
  });
    })
    .catch((error) => {
        console.log(error)
 
});;
};
// export const doCreateUserWithEmailAndPassword = async (email, password) => {
//   return createUserWithEmailAndPassword(auth, email, password)
//   .then((result) => {
//     setDoc(doc(db, "patients", auth.currentUser.uid), {
//       email
//     });
//     })
//     .catch((error) => {
//         console.log(error)
 
// });;
// };

export const doSignInWithEmailAndPassword = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;

  // add user to firestore
};

export const doSignOut = () => {
  return auth.signOut();
};

export const doPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/home`,
  });
};
