import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase33";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion12(){
    const options = ['-','Upper Back', 'Mid Back', 'Lower Back', ];
    const [myValue, setMyValue] = useState('');

    let painAreas = []
    console.log("PAIN AREAS",painAreas)

    const [upperBack, setUpperBack] = useState(false)
    const [bothBack, setBothBack] = useState(false)
    const [lowerBack, setLowerBack] = useState(false)

    const [leftShoulder, setLeftShoulder] = useState(false)
    const [rightShoulder, setRightShoulder] = useState(false)
    const [bothShoulders, setBothShoulders] = useState(false)

    const [leftElbow, setLeftElbow] = useState(false)
    const [rightElbow, setRightElbow] = useState(false)
    const [bothElbows, setBothElbows] = useState(false)

    const [leftHandWrist, setLeftHandWrist] = useState(false)
    const [rightHandWrist, setRightHandWrist] = useState(false)
    const [bothHandWrist, setBothHandWrist] = useState(false)

    const [leftHip, setLeftHip] = useState(false)
    const [rightHip, setRightHip] = useState(false)
    const [bothHips, setBothHips] = useState(false)

    const [leftKnee, setLeftKnee] = useState(false)
    const [rightKnee, setRightKnee] = useState(false)
    const [bothKnees, setBothKnees] = useState(false)

    const [leftAnkle, setLeftAnkle] = useState(false)
    const [rightAnkle, setRightAnkle] = useState(false)
    const [bothAnkles, setBothAnkles] = useState(false)

    const [leftFoot, setLeftFoot] = useState(false)
    const [rightFoot, setRightFoot] = useState(false)
    const [bothFeet, setBothFeet] = useState(false)


    const [checked2, setChecked2] = useState(false)
  const handleClick1 = () => setUpperBack(!upperBack)
  const handleClick3 = () => setBothBack(!bothBack)
  const handleClick4 = () => setLowerBack(!lowerBack)


  const handleClick2 = () => setLeftShoulder(!leftShoulder)
  const handleClick5 = () => setRightShoulder(!rightShoulder)
  const handleClick6 = () => setBothShoulders(!bothShoulders)

  const handleClick7 = () => setLeftElbow(!leftElbow)
  const handleClick8 = () => setRightElbow(!rightElbow)
  const handleClick9 = () => setBothElbows(!bothElbows)

  const handleClick10 = () => setLeftHandWrist(!leftHandWrist)
  const handleClick11 = () => setRightHandWrist(!rightHandWrist)
  const handleClick12 = () => setBothHandWrist(!bothHandWrist)

  const handleClick13 = () => setLeftHip(!leftHip)
  const handleClick14 = () => setRightHip(!rightHip)
  const handleClick15 = () => setBothHips(!bothHips)

  const handleClick16 = () => setLeftKnee(!leftKnee)
  const handleClick17 = () => setRightKnee(!rightKnee)
  const handleClick18 = () => setBothKnees(!bothKnees)

  const handleClick19 = () => setLeftAnkle(!leftAnkle)
  const handleClick20 = () => setRightAnkle(!rightAnkle)
  const handleClick21 = () => setBothAnkles(!bothAnkles)

  const handleClick22 = () => setLeftFoot(!leftFoot)
  const handleClick23 = () => setRightFoot(!rightFoot)
  const handleClick24 = () => setBothFeet(!bothFeet)
  
    const { currentUser } = useAuth()

    
    

            

        if (upperBack) {
            painAreas.push("Upper Back")


        }
        // if (upperBack) {
        //     await setDoc(userRef, {
        //         "painArea": "Upper Back"
    
        //       }, { merge: true });

        // }
        if (bothBack) {
            painAreas.push("Both Back")

        }
        // if (bothBack) {
        //     await setDoc(userRef, {
        //         "painArea": "Both Back"
    
        //       }, { merge: true });

        // }
        if (lowerBack) {
            painAreas.push("Lower Back")

        }

        // //////////////////////////////////

         if (leftShoulder) {
            painAreas.push("Left SHoulder")

        }
        if (rightShoulder) {
            painAreas.push("Right Shoulder")

        }
         if (bothShoulders) {
            painAreas.push("Both Shoulders")

        }
        //////////////////////////////////
         if (leftElbow) {
            painAreas.push("Left Elbow")

        }
         if (rightElbow) {
            painAreas.push("Right Elbow")

        }
         if (bothElbows) {
            painAreas.push("Both Elbows")

        }
        //////////////////////////////////
        if (leftHandWrist) {
            painAreas.push("Left Hand/Wrist")

        }
        if (rightHandWrist) {
            painAreas.push("Right Hand/Wrist")

        }
         if (bothHandWrist) {
            painAreas.push("Both Hand/Wrists")

        }
        //////////////////////////////////
         if (leftHip) {
            painAreas.push("Left Hip")

        }
        if (rightHip) {
            painAreas.push("Right Hip")


        }
       if (bothHips) {
            painAreas.push("Both Hips")


        }
        //////////////////////////////////
        if (leftKnee) {
            painAreas.push("Left Knee")


        }
         if (rightKnee) {
            painAreas.push("Right Knee")


        }
        if (bothKnees) {
            painAreas.push("Both Knees")


        }
        //////////////////////////////////
         if (leftAnkle) {
            painAreas.push("Left Ankle")


        }
         if (rightAnkle) {
            painAreas.push("Right Ankle")


        }
        if (bothAnkles) {
            painAreas.push("Both Ankles")


        }
        //////////////////////////////////
        if (leftFoot) {
            painAreas.push("Left Foot")


        }
        if (rightFoot) {
            painAreas.push("Right Foot")


        }
        if (bothFeet) {
            painAreas.push("Both Feet")


        }
        
        const  saveAnswer = async (event) => {
            event.preventDefault();
            const userRef = doc(db, "users" , currentUser.uid,)
       
            await setDoc(userRef, {
                        "painAreas": painAreas
            
                      }, { merge: true })
        window.location.href="/home14"

      };


    const goBack = () => {
        window.location.href="/home2"

    }
    const nextStep = () => {
        window.location.href="/home3"

    }

    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Tell us About your Pain</h3>
                            <h3 className="text-gray-800 text-xl  sm:text-2xl">Present Complaint</h3>
                            {/* <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">If so, what area?</h3> */}
                        </div>

                    </div>
                    <div>
<p>Body Area-check as many as apply: &nbsp; &nbsp;</p>
<p>(Up to 2 body parts): &nbsp; &nbsp;</p>
<p>Back  &nbsp; &nbsp; &nbsp; &nbsp; Upper <input onClick={handleClick1} checked={upperBack} type="checkbox" /> &nbsp; &nbsp;  Lower <input onClick={handleClick4} checked={lowerBack} type="checkbox" /> &nbsp; &nbsp; Both <input onClick={handleClick3} checked={bothBack} type="checkbox" /> &nbsp; &nbsp; </p>
<p>Shoulder  &nbsp; &nbsp; &nbsp; &nbsp; Left <input onClick={handleClick2} checked={leftShoulder} type="checkbox" /> &nbsp; &nbsp; Right <input onClick={handleClick5} checked={rightShoulder} type="checkbox" /> &nbsp; &nbsp; Both <input onClick={handleClick6} checked={bothShoulders} type="checkbox" /></p>
<p>Elbow  &nbsp; &nbsp; &nbsp; &nbsp;Left <input onClick={handleClick7} checked={leftElbow} type="checkbox" /> &nbsp; &nbsp; &nbsp; &nbsp;Right <input onClick={handleClick8} checked={rightElbow} type="checkbox" /> &nbsp; &nbsp;Both <input onClick={handleClick9} checked={bothElbows} type="checkbox" /></p>
<p>Hand/Wrist  &nbsp; &nbsp; &nbsp; &nbsp;Left <input onClick={handleClick10} checked={leftHandWrist} type="checkbox" /> &nbsp; &nbsp; &nbsp; &nbsp;Right <input onClick={handleClick11} checked={rightHandWrist} type="checkbox" /> &nbsp; &nbsp;Both <input onClick={handleClick12} checked={bothHandWrist} type="checkbox" />&nbsp;</p>
<p>Hip  &nbsp; &nbsp; &nbsp; &nbsp; Left <input onClick={handleClick13} checked={leftHip} type="checkbox" /> &nbsp; &nbsp; Right <input onClick={handleClick14} checked={rightHip} type="checkbox" /> &nbsp; &nbsp;Both <input onClick={handleClick15} checked={bothHips} type="checkbox" /></p>
<p>Knee  &nbsp; &nbsp; &nbsp; &nbsp;Left <input onClick={handleClick16} checked={leftKnee} type="checkbox" /> &nbsp; &nbsp; &nbsp; &nbsp;Right <input onClick={handleClick17} checked={rightKnee} type="checkbox" /> &nbsp; &nbsp;Both <input onClick={handleClick18} checked={bothKnees} type="checkbox" /></p>
<p>Ankle  &nbsp; &nbsp; &nbsp; &nbsp;Left <input onClick={handleClick19} checked={leftAnkle} type="checkbox" /> &nbsp; &nbsp; &nbsp; &nbsp;Right <input onClick={handleClick20} checked={rightAnkle} type="checkbox" /> &nbsp; &nbsp;Both <input onClick={handleClick21} checked={bothAnkles} type="checkbox" /></p>
<p>Foot  &nbsp; &nbsp; &nbsp; &nbsp;Left <input onClick={handleClick22} checked={leftFoot} type="checkbox" /> &nbsp; &nbsp; &nbsp; &nbsp;Right <input onClick={handleClick23} checked={rightFoot} type="checkbox" /> &nbsp; &nbsp;Both <input onClick={handleClick24} checked={bothFeet} type="checkbox" /></p>
</div>
                    
                    
<button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                        <button
                            onClick={goBack}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Go Back
                            
                        </button>

                                       
                        
                        
                </div>
                
            </main>
        </>
    )
}
export default HomeQuestion12;

// return (
//     <>
//         {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

//         <main className="w-full h-screen flex self-center place-content-center place-items-center">
//             {/* <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
//                 <div className="text-center mb-6">
//                     <div className="mt-2">
//                         <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Is your pain in your back?</h3>
//                         <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">If so, what area?</h3>
//                     </div>

//                 </div>
                
//                 <div class="flex justify-center items-center">
                                    
//                                     </div>


//                                     <div class="flex justify-center items-center">
//                                     <select
//                                         onChange={(e) => setMyValue(e.target.value)}
//                                         defaultValue={null}
//                                     >
//                                         {options.map((option, idx) => (
//                                         <option key={idx}>{option}</option>
//                                         ))}
//                                     </select>
                                    
//                                     </div>
               

//                                     <button
//                         type="submit"
//                         onClick={saveAnswer}
//                         className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
//                     > Next Step
                        
//                     </button>
//                     <button
//                         type="submit"
//                         onClick={nextStep}
//                         className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
//                     > I Don't Have Back Pain
                        
//                     </button>
//                     <button
//                         onClick={goBack}
//                         className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
//                     > Go Back
                        
//                     </button>
                    
                    
//             </div> */}
//             {/* <style type="text/css">
// p.p1 {
//     margin: 0.0px 0.0px 0.0px 0.0px;
//     font: 12.0px 'Times New Roman';
//     color: #000000
// }

// span.Apple-tab-span {
//     white-space: pre
// }
// </style> */}
// <div>
// <p>Area of the Body: &nbsp; &nbsp;</p>
// <p>Back  &nbsp; &nbsp; &nbsp; &nbsp; Upper __ &nbsp; &nbsp; Mid __ &nbsp; &nbsp; Lower __</p>
// <p>Shoulder  &nbsp; &nbsp; &nbsp; &nbsp; Left __ &nbsp; &nbsp; Right __ &nbsp; &nbsp; Both __</p>
// <p>Elbow  &nbsp; &nbsp; &nbsp; &nbsp;Left __ &nbsp; &nbsp; &nbsp; &nbsp;Right __ &nbsp; &nbsp;Both __</p>
// <p>Hand/Wrist  &nbsp; &nbsp; &nbsp; &nbsp;Left __ &nbsp; &nbsp; &nbsp; &nbsp;Right __ &nbsp; &nbsp;Both __&nbsp;</p>
// <p>Hip __ &nbsp; &nbsp; &nbsp; &nbsp; Left __ &nbsp; &nbsp; Right __ &nbsp; &nbsp;Both __</p>
// <p>Knee __ &nbsp; &nbsp; &nbsp; &nbsp;Left __ &nbsp; &nbsp; &nbsp; &nbsp;Right __ &nbsp; &nbsp;Both __</p>
// <p>Ankle __ &nbsp; &nbsp; &nbsp; &nbsp;Left __ &nbsp; &nbsp; &nbsp; &nbsp;Right __ &nbsp; &nbsp;Both __</p>
// <p>Foot __ &nbsp; &nbsp; &nbsp; &nbsp;Left __ &nbsp; &nbsp; &nbsp; &nbsp;Right __ &nbsp; &nbsp;Both __</p>
// </div>
//         </main>
//     </>
// )