import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase33";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion4(){
    const painLocations = ['-','Inside', 'Outside', 'Front', 'Back'];
    const painLengths = ['-','A Few Days or Weeks', 'A Few Months or so', 'For a While Now'];
    const painActivities = ['-','All The Time', 'When Walking or Standing', 'When Sitting','Only Occasionally'];
    const painYoureFeeling = ['-','Throbbing', 'Aching', 'My Knees Lock Up','My Knee is Stiff'];
    const mriadministeredyesno = ['-','Yes', 'No'];
    const spendalotoftimeyesno = ['-','Yes', 'No'];
    const feelUstableYesno = ['-','Yes', 'No'];
    const heatOintmentsyesno = ['-','Yes', 'No'];
    const arthritisYesNo = ['-','Yes', 'No'];
    const mcyesno = ['-','Yes', 'No'];
    const kneeinjuryyesno = ['-','Yes', 'No'];

    const [userPainLocation, setUserPainLocation] = useState(painLocations[0]);
    const [howLongHaveYouFeltThisPain, setHowLongHaveYouFeltThisPain] = useState(painLengths[0]);
    const [doYouFeelThePainAllTheTime, setDoYouFeelThePainAllTheTime] = useState(painActivities[0]);
    const [describeThePainYoureFeeling, setDescribeThePainYoureFeeling] = useState(painYoureFeeling[0]);
    const [mriAdministered, setMriAdministered] = useState(mriadministeredyesno[0]);
    const [spendalotoftime, setspendalotoftime] = useState(spendalotoftimeyesno[0]);
    const [feelUnstable, setFeelUnstable] = useState(feelUstableYesno[0]);
    const [heatOintments, setHeatOintments] = useState(heatOintmentsyesno[0]);
    const [arthritis, setArthritis] = useState(heatOintmentsyesno[0]);
    const [mc, setMc] = useState(mcyesno[0]);
    const [kneeinjury, setKneeinjury] = useState(kneeinjuryyesno[0]);




    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

     


        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            "UserPainLocation":userPainLocation,
            'HowLongHaveyouFeltthispain?': howLongHaveYouFeltThisPain,
            'Doyoufeelthepainallthetime?': doYouFeelThePainAllTheTime,
            "Describethepainyourefeeling": describeThePainYoureFeeling,
            "DidyourphysicianorderanMRI,XRayorinjectionsforthispain?" : mriAdministered,
            'Doyouspendalotoftimewalking,exercising,orjumping,anddothesebotheryourknee?': spendalotoftime,
            'Doesthekneefeelunstableorgivewaywithstandingorwalking?': feelUnstable,
            'Haveyoutriedheat/​iceointmentsorananti-inflammatory,suchasibuprofen,tohelpeasethepain?':heatOintments,
            'Haveyoueverbeendiagnosedwitharthritis,meniscus tear,ligamentinjury,orsciatica?' : arthritis ,
            'Doyouhaveanyothermedicalconditionsbesidesthispain?' : mc ,
            'Doyouhaveahistoryofkneeinjuryand/​orsurgery?' : kneeinjury

          }, { merge: true });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
         window.location.href="/home10"

      };
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">{`Tell us about your pain..`}</h3>
                        </div>

                    </div>






                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Are you having any pain or swelling in your knee? If so, where is it located?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setUserPainLocation(e.target.value)}
                            defaultValue={userPainLocation}
                        >
                            {painLocations.map((painLocation, idx) => (
                            <option key={idx}>{painLocation}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>


                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>How long have you felt this pain?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setHowLongHaveYouFeltThisPain(e.target.value)}
                            defaultValue={howLongHaveYouFeltThisPain}
                        >
                            {painLengths.map((painLength, idx) => (
                            <option key={idx}>{painLength}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>

                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Do you feel the pain all the time, or just during certain activities?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setDoYouFeelThePainAllTheTime(e.target.value)}
                            defaultValue={doYouFeelThePainAllTheTime}
                        >
                            {painActivities.map((painActivity, idx) => (
                            <option key={idx}>{painActivity}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Describe the pain you're feeling...</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setDescribeThePainYoureFeeling(e.target.value)}
                            defaultValue={describeThePainYoureFeeling}
                        >
                            {painYoureFeeling.map((painVal, idx) => (
                            <option key={idx}>{painVal}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Did your physician order an MRI, XRay or injections for this pain?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setMriAdministered(e.target.value)}
                            defaultValue={mriAdministered}
                        >
                            {mriadministeredyesno.map((yesno, idx) => (
                            <option key={idx}>{yesno}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Do you spend a lot of time walking, exercising, or jumping, and do these bother your knee?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setspendalotoftime(e.target.value)}
                            defaultValue={spendalotoftime}
                        >
                            {spendalotoftimeyesno.map((yesno1, idx) => (
                            <option key={idx}>{yesno1}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Does the knee feel unstable or give way with standing or walking?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setFeelUnstable(e.target.value)}
                            defaultValue={feelUnstable}
                        >
                            {feelUstableYesno.map((yesno2, idx) => (
                            <option key={idx}>{yesno2}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Have you tried heat/​ice ointments or an anti-inflammatory, such as ibuprofen, to help ease the pain?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setHeatOintments(e.target.value)}
                            defaultValue={heatOintments}
                        >
                            {heatOintmentsyesno.map((yesno3, idx) => (
                            <option key={idx}>{yesno3}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Have you ever been diagnosed with arthritis, meniscus tear, ligament injury, or sciatica?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setArthritis(e.target.value)}
                            defaultValue={arthritis}
                        >
                            {arthritisYesNo.map((yesno4, idx) => (
                            <option key={idx}>{yesno4}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Do you have any other medical conditions besides this pain?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setMc(e.target.value)}
                            defaultValue={mc}
                        >
                            {mcyesno.map((yesno5, idx) => (
                            <option key={idx}>{yesno5}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    <div class="container">
                    <div class="row  justify-content-center">
                    
                    <div class="col-4">
                        <h2><b>Do you have a history of knee injury and/​or surgery?</b></h2>
                    </div>
                    <div class="col-4">
                        <select
                            onChange={(e) => setKneeinjury(e.target.value)}
                            defaultValue={kneeinjury}
                        >
                            {mcyesno.map((yesno5, idx) => (
                            <option key={idx}>{yesno5}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                    </div>
                    







                   

                        <button
                            type="submit"
                            onClick={saveAnswer}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                </div>
            </main>
        </>
    )
}
export default HomeQuestion4;