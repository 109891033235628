import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getStorage} from 'firebase/storage';
import { getFirestore } from "firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyAGvW75cDGl8LNUgHTq7_i7dJrLjaORNyc",
  authDomain: "a-custom-fit-in-motion.firebaseapp.com",
  projectId: "a-custom-fit-in-motion",
  storageBucket: "a-custom-fit-in-motion.appspot.com",
  messagingSenderId: "684153843025",
  appId: "1:684153843025:web:0d356495e774c8c5f8f770",
  measurementId: "G-K617T45V5K"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);
// export const storage = firebase.storage()


export const imageDb = getStorage(app)

export { app, auth, db, firebaseConfig };
