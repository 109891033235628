import React from 'react'
import { useAuth } from '../../contexts/authContext'
import FirebaseImageUpload from './FirebaseImageUpload'
import Survey1 from './survey1'
import {useParams} from 'react-router-dom';


const Home = () => {
    const { currentUser } = useAuth()
    const queryParameters = new URLSearchParams(window.location.search)
    const type = queryParameters.get("type")
    const name = queryParameters.get("name")
    return (<>
        {/* <FirebaseImageUpload/> */}
        {type}
         <Survey1/>
        </>
    )
}

export default Home