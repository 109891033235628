import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase33";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion8(){
    const [primaryCarePhysician, setPrimaryCarePhysician] = useState("");
    const [dlv, setDlv] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [wasteSize, setWasteSize] = useState("");
    const { currentUser } = useAuth()

    
    const  saveAnswer = async (event) => {
        event.preventDefault();

        // const userRef = doc(db, "patients" , currentUser.uid,)
        const userRef = doc(db, "users" , currentUser.uid,)


        await setDoc(userRef, {
            primaryCarePhysician, 
            dlv,
            height, 
            weight,
            wasteSize
          }, { merge: true });

        // const docRef = firebase.firestore().collection('users').doc(firebase.auth()?.currentUser?.uid).collection("codes")
        //       docRef.add({
        //         address,
        //         creation: firebase.firestore.FieldValue.serverTimestamp()
        //     })
        window.location.href="/home9"

      };

      const goBack = () => {
        window.location.href="/home"

    }
    
    //   return (
    //     <div className="container">
    //       <h1>What are some of your favorite tunes?</h1>
    //       <form onSubmit={saveAnswer}>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Street' 
    //         value={street}
    //         onChange={(e)=> {setStreet(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your City' 
    //         value={city}
    //         onChange={(e)=> {setCity(e.target.value)}}
    //         ></input>
    //       <input 
    //         type="text" 
    //         placeholder='Enter your Zip' 
    //         value={zip}
    //         onChange={(e)=> {setZip(e.target.value)}}
    //         ></input>
    //         <button type="submit">Submit to Firebase</button>
    //       </form>
    //     </div>
    //   );
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-96 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-xl font-semibold sm:text-2xl">Medical History</h3>
                        </div>

                    </div>
                    <form
                        onSubmit={saveAnswer}
                        className="space-y-4"
                    >
                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Name of your primary care physician:
                            </label>
                            <input
                                type="text"
                               
                                required
                                value={primaryCarePhysician} onChange={(e) => { setPrimaryCarePhysician(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Date of last visit:
                            </label>
                            <input
                               
                                type="text"
                                autoComplete='new-password'
                                required
                                value={dlv} onChange={(e) => { setDlv(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Height - ft & in:
                            </label>
                            <input
                               
                                type="text"
                                autoComplete='new-password'
                                required
                                value={height} onChange={(e) => { setHeight(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Weight - lbs.
                            </label>
                            <input
                                
                                type="text"
                                autoComplete='off'
                                required
                                value={weight} onChange={(e) => { setWeight(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>
                        <div>
                            <label className="text-sm text-gray-600 font-bold">
                            Waist Size
                            </label>
                            <input
                                
                                type="text"
                                autoComplete='off'
                                required
                                value={wasteSize} onChange={(e) => { setWasteSize(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                            />
                        </div>

                        <button
                            onClick={goBack}
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Go Back
                            
                        </button>

                        <button
                            type="submit"
                            className='w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-700 hover:shadow-xl transition duration-300'
                        > Next Step
                            
                        </button>
                        
                    </form>
                </div>
            </main>
        </>
    )
}
export default HomeQuestion8;