import React, { useEffect, useState } from "react";
import {  db } from "../../firebase/firebase33";
import { collection, getCountFromServer, getFirestore } from '@firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useAuth } from '../../contexts/authContext'

import { doc, setDoc } from "firebase/firestore"; 



function HomeQuestion7(){
    
    
    return (
        <>
            {/* {userLoggedIn && (<Navigate to={'/home'} replace={true} />)} */}

            <main className="w-full h-screen flex self-center place-content-center place-items-center">
                <div className="w-150 text-gray-600 space-y-5 p-4 shadow-xl border rounded-xl">
                    <div className="text-center mb-6">
                    <div class="flex justify-center items-center">
                    <img src={require('../../fittedbrace.png')} height={250 } width={500}   />
                    </div>   
                        <div className="mt-4">
                            <h3 className="text-gray-800 text-xl font-semibold mt-4 sm:text-2xl">{`Successfully Submitted!`}</h3>
                            <p className="text-gray-800 text-md mt-4">{`Thank you for trusting Fitted Brace as your pain relief advocates!`}</p>
                            <p className="text-gray-800 text-md">{`Our team will get to work by contacting your doctor for a prescription for your brace(s).
`}</p>
                            <p className="text-gray-800 text-md">{`You will receive email updates as we go through the process.`}</p>

                        </div>
                        

                    </div>
                    </div>
                    
            </main>
        </>
    )
}
export default HomeQuestion7;