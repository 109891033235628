import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/authContext'
import FirebaseImageUpload from './FirebaseImageUpload'
import Survey1 from './survey1'
import Calendly from './Calendly'
import CustomizedSteppers from './ProgressBar'
import firebase from "firebase/compat/app";
require("firebase/firestore");
import { firebaseConfig } from '../../firebase/firebase33'

// const typeOut = () => {
//   console.log('clicked')
// }

// const scheduleButton = document.querySelectorAll('[type=submit]')


// scheduleButton.addEventListener('click', typeOut())

const PatientDashboard = () => {
    const { currentUser } = useAuth()
    const [user, setUser] = useState(null);
    console.log('USER',user)
    console.log('USER',currentUser.uid)

    firebase.initializeApp(firebaseConfig);

    

    


    useEffect(() => {
          firebase
            .firestore()
            .collection("users")
            .doc(currentUser.uid)
            .get()
            .then((snapshot) => {
              if (snapshot.exists) {
                setUser(snapshot.data());
              } else {
                console.log("does not exist");
              }
            });
      }, []);

    if (user) {
      return (<>
        <div style={{display: 'flex', justifyContent: 'center'}}>
        {/* <div className='text-2xl font-bold pt-14 mt-40 '>Hello {currentUser.email}, please schedule your fitting.</div> */}
    
        <div className='col'>

       { (user.status === 4 || user.status === 5) ?
                <div className='text-2xl font-bold pt-14 mt-40 '>Fitting Scheduled for {user.fittingTime}</div>

                : 
                <div className='text-2xl font-bold pt-14 mt-40 '>Fitting not yet scheduled</div>

        
        
        
        }
        


        </div>
        

    </div>
            {/* <FirebaseImageUpload/> */}
             {/* <Calendly /> */}
             {/* <div>Hi</div> */}
             <CustomizedSteppers status={user.status}/>
            </>
        )
    
    }
    
}

export default PatientDashboard